import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';

import { createSelector } from 'reselect';
import type { RootState } from 'store';
import type { OmsSelector } from 'types/common';

import type { FeatureFlags, FeatureFlagsVariant, VariantOrBoolean } from './types';

type FeatureFlagState = RootState['featureFlags'];

const getFeatureFlagState: OmsSelector<FeatureFlagState> = state => state?.featureFlags;

// Get state.featureFlags.flags
const getFlags: OmsSelector<FeatureFlagState['flags']> = createSelector(
  getFeatureFlagState,
  get('flags')
);

// Get state.featureFlags.flagFetchStatus
const getFlagsFetchStatus: OmsSelector<FeatureFlagState['status']> = createSelector(
  getFeatureFlagState,
  get('status')
);
// Get state.featureFlags.flagFetchStatus === rejected || fulfilled
export const isFlagsDoneFetching: OmsSelector<boolean> = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus => flagFetchStatus === 'rejected' || flagFetchStatus === 'fulfilled'
);

// Get state.featureFlags.flags.data-only-ui
const getDataOnlyUiFlag: OmsSelector<FeatureFlags['data-only-ui']> = createSelector(
  getFlags,
  get('data-only-ui')
);
// Get state.featureFlags.flags.data-only-ui.active
const isDataOnlyUiFlagActive: OmsSelector<boolean> = createSelector(
  getDataOnlyUiFlag,
  get('active')
);
// Get state.featureFlags.flags.data-only-ui.variant
const getDataOnlyUiFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getDataOnlyUiFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDataOnlyUi: OmsSelector<VariantOrBoolean> = createSelector(
  isDataOnlyUiFlagActive,
  getDataOnlyUiFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDataOnlyUi: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForDataOnlyUi,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);

// Get state.featureFlags.flags.selfies-error-detection
const getSelfiesErrorDetectionFlag: OmsSelector<FeatureFlags['selfies-error-detection']> =
  createSelector(getFlags, get('selfies-error-detection'));
// Get state.featureFlags.flags.selfies-error-detection.active
const isSelfiesErrorDetectionFlagActive: OmsSelector<boolean> = createSelector(
  getSelfiesErrorDetectionFlag,
  get('active')
);
// Get state.featureFlags.flags.selfies-error-detection.variant
const getSelfiesErrorDetectionFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSelfiesErrorDetectionFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSelfiesErrorDetection: OmsSelector<VariantOrBoolean> = createSelector(
  isSelfiesErrorDetectionFlagActive,
  getSelfiesErrorDetectionFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSelfiesErrorDetection: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSelfiesErrorDetection,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.selfies
const getSelfiesFlag: OmsSelector<FeatureFlags['selfies']> = createSelector(
  getFlags,
  get('selfies')
);
// Get state.featureFlags.flags.selfies.active
const isSelfiesFlagActive: OmsSelector<boolean> = createSelector(getSelfiesFlag, get('active'));
// Get state.featureFlags.flags.selfies.variant
const getSelfiesFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSelfiesFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSelfies: OmsSelector<VariantOrBoolean> = createSelector(
  isSelfiesFlagActive,
  getSelfiesFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSelfies: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSelfies,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.dispatch-modal-box-status
const getDispatchModalBoxStatusFlag: OmsSelector<FeatureFlags['dispatch-modal-box-status']> =
  createSelector(getFlags, get('dispatch-modal-box-status'));
// Get state.featureFlags.flags.dispatch-modal-box-status.active
const isDispatchModalBoxStatusFlagActive: OmsSelector<boolean> = createSelector(
  getDispatchModalBoxStatusFlag,
  get('active')
);
// Get state.featureFlags.flags.dispatch-modal-box-status.variant
const getDispatchModalBoxStatusFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getDispatchModalBoxStatusFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDispatchModalBoxStatus: OmsSelector<VariantOrBoolean> = createSelector(
  isDispatchModalBoxStatusFlagActive,
  getDispatchModalBoxStatusFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDispatchModalBoxStatus: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForDispatchModalBoxStatus,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.microhold-palletization
const getMicroholdPalletizationFlag: OmsSelector<FeatureFlags['microhold-palletization']> =
  createSelector(getFlags, get('microhold-palletization'));
// Get state.featureFlags.flags.microhold-palletization.active
const isMicroholdPalletizationFlagActive: OmsSelector<boolean> = createSelector(
  getMicroholdPalletizationFlag,
  get('active')
);
// Get state.featureFlags.flags.microhold-palletization.variant
const getMicroholdPalletizationFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getMicroholdPalletizationFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForMicroholdPalletization: OmsSelector<VariantOrBoolean> = createSelector(
  isMicroholdPalletizationFlagActive,
  getMicroholdPalletizationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);

export const shouldShowMicroholdPalletizationPhase1: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-1'
);

export const shouldShowMicroholdPalletizationPhase2: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-2'
);

export const shouldShowMicroholdPalletizationPhase3: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-3'
);

export const shouldShowMicroholdPalletizationPhase4: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-4'
);

export const shouldShowMicroholdPalletizationPhase5: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMicroholdPalletization,
  activeOrVariant => activeOrVariant === 'phase-5'
);

export const shouldShowMicroholdPalletizationPhase: OmsSelector<boolean> = createSelector(
  [
    shouldShowMicroholdPalletizationPhase1,
    shouldShowMicroholdPalletizationPhase2,
    shouldShowMicroholdPalletizationPhase3,
    shouldShowMicroholdPalletizationPhase4,
    shouldShowMicroholdPalletizationPhase5,
  ],
  (phase1, phase2, phase3, phase4, phase5) => phase1 || phase2 || phase3 || phase4 || phase5
);
// Get state.featureFlags.flags.accessory-gwp
const getAccessoryGwpFlag: OmsSelector<FeatureFlags['accessory-gwp']> = createSelector(
  getFlags,
  get('accessory-gwp')
);
// Get state.featureFlags.flags.accessory-gwp.active
const isAccessoryGwpFlagActive: OmsSelector<boolean> = createSelector(
  getAccessoryGwpFlag,
  get('active')
);
// Get state.featureFlags.flags.accessory-gwp.variant
const getAccessoryGwpFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getAccessoryGwpFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForAccessoryGwp: OmsSelector<VariantOrBoolean> = createSelector(
  isAccessoryGwpFlagActive,
  getAccessoryGwpFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowAccessoryGwp: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForAccessoryGwp,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.short-polling-download-pdf-documents
const getShortPollingDownloadPdfDocumentsFlag: OmsSelector<
  FeatureFlags['short-polling-download-pdf-documents']
> = createSelector(getFlags, get('short-polling-download-pdf-documents'));
// Get state.featureFlags.flags.short-polling-download-pdf-documents.active
const isShortPollingDownloadPdfDocumentsFlagActive: OmsSelector<boolean> = createSelector(
  getShortPollingDownloadPdfDocumentsFlag,
  get('active')
);
// Get state.featureFlags.flags.short-polling-download-pdf-documents.variant
const getShortPollingDownloadPdfDocumentsFlagVariant: OmsSelector<FeatureFlagsVariant> =
  createSelector(getShortPollingDownloadPdfDocumentsFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForShortPollingDownloadPdfDocuments: OmsSelector<VariantOrBoolean> =
  createSelector(
    isShortPollingDownloadPdfDocumentsFlagActive,
    getShortPollingDownloadPdfDocumentsFlagVariant,
    (active, variant) => (active && !isNil(variant) ? variant : active)
  );
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowShortPollingDownloadPdfDocuments: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForShortPollingDownloadPdfDocuments,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);

// Get state.featureFlags.flags.brush-consolidation
const getBrushConsolidationFlag: OmsSelector<FeatureFlags['brush-consolidation']> = createSelector(
  getFlags,
  get('brush-consolidation')
);
// Get state.featureFlags.flags.brush-consolidation.active
const isBrushConsolidationFlagActive: OmsSelector<boolean> = createSelector(
  getBrushConsolidationFlag,
  get('active')
);
// Get state.featureFlags.flags.brush-consolidation.variant
const getBrushConsolidationFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getBrushConsolidationFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForBrushConsolidation: OmsSelector<VariantOrBoolean> = createSelector(
  isBrushConsolidationFlagActive,
  getBrushConsolidationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowBrushConsolidation: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForBrushConsolidation,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);

// Get state.featureFlags.flags.multi-lane-improvements
const getMultiLaneImprovementsFlag: OmsSelector<FeatureFlags['multi-lane-improvements']> =
  createSelector(getFlags, get('multi-lane-improvements'));
// Get state.featureFlags.flags.multi-lane-improvements.active
const isMultiLaneImprovementsFlagActive: OmsSelector<boolean> = createSelector(
  getMultiLaneImprovementsFlag,
  get('active')
);
// Get state.featureFlags.flags.multi-lane-improvements.variant
const getMultiLaneImprovementsFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getMultiLaneImprovementsFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForMultiLaneImprovements: OmsSelector<VariantOrBoolean> = createSelector(
  isMultiLaneImprovementsFlagActive,
  getMultiLaneImprovementsFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowMultiLaneImprovements: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForMultiLaneImprovements,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.buffer-bin-complete-message
const getBufferBinCompleteMessageFlag: OmsSelector<FeatureFlags['buffer-bin-complete-message']> =
  createSelector(getFlags, get('buffer-bin-complete-message'));
// Get state.featureFlags.flags.buffer-bin-complete-message.active
const isBufferBinCompleteMessageFlagActive: OmsSelector<boolean> = createSelector(
  getBufferBinCompleteMessageFlag,
  get('active')
);
// Get state.featureFlags.flags.buffer-bin-complete-message.variant
const getBufferBinCompleteMessageFlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getBufferBinCompleteMessageFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForBufferBinCompleteMessage: OmsSelector<VariantOrBoolean> = createSelector(
  isBufferBinCompleteMessageFlagActive,
  getBufferBinCompleteMessageFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowBufferBinCompleteMessage: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForBufferBinCompleteMessage,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.survey-updates-phase-1
const getSurveyUpdatesPhase1Flag: OmsSelector<FeatureFlags['survey-updates-phase-1']> =
  createSelector(getFlags, get('survey-updates-phase-1'));
// Get state.featureFlags.flags.survey-updates-phase-1.active
const isSurveyUpdatesPhase1FlagActive: OmsSelector<boolean> = createSelector(
  getSurveyUpdatesPhase1Flag,
  get('active')
);
// Get state.featureFlags.flags.survey-updates-phase-1.variant
const getSurveyUpdatesPhase1FlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSurveyUpdatesPhase1Flag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSurveyUpdatesPhase1: OmsSelector<VariantOrBoolean> = createSelector(
  isSurveyUpdatesPhase1FlagActive,
  getSurveyUpdatesPhase1FlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSurveyUpdatesPhase1: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSurveyUpdatesPhase1,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.data-driven-production-flows-front
const getDataDrivenProductionFlowsFrontFlag: OmsSelector<
  FeatureFlags['data-driven-production-flows-front']
> = createSelector(getFlags, get('data-driven-production-flows-front'));
// Get state.featureFlags.flags.data-driven-production-flows-front.active
const isDataDrivenProductionFlowsFrontFlagActive: OmsSelector<boolean> = createSelector(
  getDataDrivenProductionFlowsFrontFlag,
  get('active')
);
// Get state.featureFlags.flags.data-driven-production-flows-front.variant
const getDataDrivenProductionFlowsFrontFlagVariant: OmsSelector<FeatureFlagsVariant> =
  createSelector(getDataDrivenProductionFlowsFrontFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDataDrivenProductionFlowsFront: OmsSelector<VariantOrBoolean> =
  createSelector(
    isDataDrivenProductionFlowsFrontFlagActive,
    getDataDrivenProductionFlowsFrontFlagVariant,
    (active, variant) => (active && !isNil(variant) ? variant : active)
  );
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDataDrivenProductionFlowsFront: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForDataDrivenProductionFlowsFront,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
// Get state.featureFlags.flags.survey-updates-phase-2
const getSurveyUpdatesPhase2Flag: OmsSelector<FeatureFlags['survey-updates-phase-2']> =
  createSelector(getFlags, get('survey-updates-phase-2'));
// Get state.featureFlags.flags.survey-updates-phase-2.active
const isSurveyUpdatesPhase2FlagActive: OmsSelector<boolean> = createSelector(
  getSurveyUpdatesPhase2Flag,
  get('active')
);
// Get state.featureFlags.flags.survey-updates-phase-2.variant
const getSurveyUpdatesPhase2FlagVariant: OmsSelector<FeatureFlagsVariant> = createSelector(
  getSurveyUpdatesPhase2Flag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSurveyUpdatesPhase2: OmsSelector<VariantOrBoolean> = createSelector(
  isSurveyUpdatesPhase2FlagActive,
  getSurveyUpdatesPhase2FlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSurveyUpdatesPhase2: OmsSelector<boolean> = createSelector(
  getActiveOrVariantForSurveyUpdatesPhase2,
  activeOrVariant => {
    if (typeof activeOrVariant === 'boolean') return activeOrVariant;
    return activeOrVariant === 'on';
  }
);
